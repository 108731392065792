<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Register v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <h2 class="brand-text text-primary ml-1">
            CloudFit Pro
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Manage Credit Card 🚀
        </b-card-title>
        <b-card-text class="mb-2">

        </b-card-text>

        <!-- form -->
        <validation-observer ref="registerForm">
          <h4 class="brand-text text-primary ml-1" v-if="valid === false">Invalid Link</h4>
          <b-form v-if="insert === false && valid === true">
            <b-form-group
              label="Member Code"
              label-for="card"
            >
              <b-form-input
                id="member_code"
                v-model="member.member_code"
                trim
                readonly
              />

            </b-form-group>

            <b-form-group
              label="Mobile"
              label-for="mobile"
            >
              <b-form-input
                id="mobile"
                v-model="member.mobile"
                trim
                readonly
              />

            </b-form-group>

             <b-form-group
               label="Credit Card Number"
               label-for="card"
             >
               <b-form-input
                 id="card"
                  v-mask="'#### #### #### ####'"
                 v-model="card.number"
                 trim
               />

             </b-form-group>

           <b-form-group
             label="Expiry Date (YYYY-MM)"
             label-for="Expiry Date"
           >
             <b-form-input
               id="expire"
               v-mask="'####-##'"
               v-model="card.expire"
               trim
             />

           </b-form-group>

          <b-form-group
            label="CVC"
            label-for="cvc"
          >
            <b-form-input
              id="cvc"
              v-mask="'###'"
              v-model="card.cvc"
              trim
            />

          </b-form-group>

            <!-- checkbox -->
            <!--<b-form-group>
              <b-form-checkbox
                id="register-privacy-policy"
                v-model="status"
                name="checkbox-1"
              >
                I agree to
                <b-link>privacy policy & terms</b-link>
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="button"
              @click="add_card"
            >
              Submit
            </b-button>
          </b-form>
        </validation-observer>




      </b-card>
    <!-- /Register v1 -->
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BCardTitle, BCardText, BForm,
  BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import router from '@/router'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VuexyLogo,
    // BSV
    BCard,
    BLink,
    BCardTitle,
    router,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      regEmail: '',
      username: '',
      valid : true,
      password: '',
      status: '',
      insert: false,
      member : {
        member_code: null,
        mobile: null
      },
      card: {
        number : null,
        expire : null,
        cvc: null,
        center_id:  router.currentRoute.params.center_id
      },
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created() {
    this.get_member()
  },
  methods: {
    get_member() {
      this.$http.get(process.env.VUE_APP_API_BASE+`/member/token/`+ router.currentRoute.params.token + `/`)
        .then(res => {
          if (res.data.result === false ) {
            this.valid = false
          } else {
            this.member = res.data
          }
        })
    },
    add_card() {
      this.$http.post(process.env.VUE_APP_API_BASE+'/member/token/'+ router.currentRoute.params.token +'/credit_card/add/', this.card)
        .then(res => {
          if (res.data.result === true) {
            this.insert = true
            this.card.number = null
            this.card.expire = null
            this.card.cvc = null
            this.$swal({
              title: 'Success!',
              text: 'You inserted a valid credit card!',
              icon: 'success',
              showConfirmButton : false,
              buttonsStyling: false,
              backdrop: false,
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: 'Error',
                icon: 'XIcon',
                variant: 'danger',
                text: res.data.message,
              },
            })
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
